import React, { Component } from 'react';
import './Footer.css'; // You'll need to create this CSS file for styling

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Cars</h3>
            <ul>
              <li>Alto K10</li>
              <li>Celerio</li>
              <li>Dzire</li>
              <li>Brezza</li>
              <li>S-Presso</li>
              <li>WagonR</li>
              <li>Swift</li>
              <li>Ertiga</li>
              <li>Eeco</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Business Hours</h3>
            <p>Mon - Sun: 09:00 AM - 07:00 PM</p>
          </div>
          <div className="footer-section">
            <h3>Showroom Address</h3>
            <p>Maruti Suzuki ARENA, Ring Road Mangalabag</p>
            <p>Tushi Motors Pvt. Ltd.</p>
            <p>River Oaks Plaza, Mahanadi Ring Road,</p>
            <p>Plot no.-1507, Buxibazar, Cuttack, Odisha -753001</p>
            <p>Phone: 07941050788</p>
          </div>
          <div className="footer-section">
            <h3>Google Reviews</h3>
            <div className="stars">
              <span>★★★★★</span>
            </div>
            <button className="review-btn">Review Us</button>
            <div className="social-media">
              <p>Like us on Facebook</p>
              <button className="facebook-btn">Facebook</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
