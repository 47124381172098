import React, { Component } from 'react';
import Header from '../../Home/components/headers';
import HeroSection from '../../Home/components/herosection';
import FeaturedCars from '../../Home/components/feturecar';
import ContactForm from '../../contact/compoents/contact';
import Footer from '../../Home/components/footer';
import ContentSection from '../../Home/components/content';
import Footer1 from '../../Home/components/footer1';
import CarCarousel from '../../Home/components/CarCarousel';
class App extends Component {
    render() {
        return (
            <div>
                <Header />
                <HeroSection />
                <CarCarousel />
                <FeaturedCars />
                <ContentSection />
                {/* <ContactForm /> */}
                <Footer1 />
                <Footer />
            </div>
        );
    }
}

export default App;
