import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';

class Footer extends Component {
  render() {
    return (
      <footer style={{ backgroundColor: '#333', color: 'white', padding: '20px 0', marginTop: '40px' }}>
        <Container>
          <Typography variant="body1" align="center">
            &copy; 2024 Arena of Ring Road Mangalabag. All Rights Reserved.
          </Typography>
        </Container>
      </footer>
    );
  }
}

export default Footer;
