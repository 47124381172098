import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarCarousel.css'; // Custom CSS for styling

class CarRange extends Component {
    render() {
        return (
            <div className="car-range-container">
                <h1 className="car-range-header">CAR RANGE</h1>
                <div className="car-range-content">
                    <div className="sidebar">
                        <button className="sidebar-button">Download Brochure</button>
                        <button className="sidebar-button">Compare Variants</button>
                        <button className="sidebar-button">EMI Calculator</button>
                        <button className="sidebar-button">E-Book Now</button>
                    </div>

                    <div className="carousel-wrapper">
                        <Carousel interval={1400} pause={false}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/alto-k10/car-image/altok10.ashx?modified=20240614090911"
                                    alt="Alto K10"
                                />
                                <Carousel.Caption>
                                    <h3>Alto K10</h3>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/s-presso/home-slider-image/swift.ashx?modified=20240614092031"
                                    alt="S-Presso"
                                />
                                <Carousel.Caption>
                                    <h3>S-Presso</h3>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/wagon-r/car-image/strong-black-and-red.ashx?modified=20240614091927"
                                    alt="WagonR"
                                />
                                <Carousel.Caption>
                                    <h3>WagonR</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/swift/car-image/swift02.ashx?modified=20240614091824"
                                    alt="Swift"
                                />
                                <Carousel.Caption>
                                    <h3>Swift</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/dzire/home-slider-image/dzire.ashx?modified=20240521101123"
                                    alt="Dzire"
                                />
                                <Carousel.Caption>
                                    <h3>Dzire</h3>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/ertiga/home-slider-image/ertiga.ashx?modified=20240614091623"
                                    alt="Ertiga"
                                />
                                <Carousel.Caption>
                                    <h3>Ertiga</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/brezza/car-image/brezza-page.ashx?modified=20240614091101"
                                    alt="Brezza"
                                />
                                <Carousel.Caption>
                                    <h3>Brezza</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/eeco/home-slider-image/eeco.ashx?modified=20240614091449"
                                    alt="Eeco"
                                />
                                <Carousel.Caption>
                                    <h3>Eeco</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/celerio/car-image/celerio.ashx?modified=20240614091213"
                                    alt="Celerio"
                                />
                                <Carousel.Caption>
                                    <h3>Celerio</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        );
    }
}

export default CarRange;
