import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Logo from '../components/image/ARENA TUSHI MOTORS copy.png'; // Ensure the path is correct
import Logo1 from '../components/image/Maruti_Suzuki_Logo_PNG1.png'; // Ensure the path is correct

class Header extends Component {
    render() {
        return (
            <Box>
                {/* First AppBar with blue background, white font, and call icon with contact info */}
                <AppBar position="static" sx={{ backgroundColor: '#2d3494', height: 70 }}> {/* Fixed height for AppBar */}
                    <Toolbar sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <img
                                src={Logo}
                                alt="Maruti Suzuki Logo"
                                style={{
                                    height: 250, // Logo height
                                    width: 'auto', // Auto width to maintain aspect ratio
                                    // filter: 'invert(1)' // Invert colors for white logo on blue background
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton edge="end" color="inherit" aria-label="call">
                                <PhoneIcon />
                            </IconButton>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: 'white',
                                    marginLeft: 1,
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 'medium'
                                }}
                            >
                                <strong> Call Us: 07941050788</strong>
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* Second AppBar with white background and black font */}
                <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black', height: 70 }}> {/* Fixed height for AppBar */}
                    <Toolbar sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <img
                                src={Logo1}
                                alt="Maruti Suzuki Logo"
                                style={{
                                    height: 70, // Logo height
                                    width: 'auto', // Auto width to maintain aspect ratio
                                }}
                            />

                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button
                                    color="inherit"
                                    sx={{
                                        color: 'black',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 'medium',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '4px'
                                        }
                                    }}
                                >
                                    Home
                                </Button>
                            </Link>
                            <Link to="/Contact" style={{ textDecoration: 'none' }}>
                                <Button
                                    color="inherit"
                                    sx={{
                                        color: 'black',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 'medium',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '4px'
                                        }
                                    }}
                                >
                                    Contact
                                </Button>
                            </Link>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
}

export default Header;
