import React, { Component } from 'react';
import './FeaturedCars.css';

class FeaturedCars extends Component {
    render() {
        return (
            <>
                <h1>ACCESSORIES</h1>
                <div id="main">
                    <div id="access">
                        <div><p>Alloy-wheel New Breeza</p></div>
                    </div>
                    <div id="access2">
                        <div id="access21">
                            <div id="access211">
                                <div>Ertiga Stylish Fog Lights</div>
                            </div>
                            <div id="access212">
                                <div>D-zire Leather Seats</div>
                            </div>
                            <div id="access213">
                                <div>D-zire Carpet Map</div>
                            </div>
                        </div>
                        <div id="access22">
                            <div id="access221">
                                <div>Maruti Suzuki Dzire-Illuminated Door Sill Guard</div>
                            </div>
                            <div id="access222">
                                <div>Maruti Suzuki Dzire-Interior Styling Kit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FeaturedCars;
