import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Components/Controller.js";
// import Drawer from "../Containers/DrawerCont.js";

// import Login from "../../Auth/Containers/Login_Cont.js";
import Home from "../../Pages/main/components/nhome.js";
import Contact from "../../Pages/contact/compoents/contact.js";


class Routers extends Component {
    render() {
        const { login } = this.props;
        let body;
        let isHome = true

        if (isHome) {
            body = (
                <div>
                    <main style={{ flexGrow: 1 }}>
                        <Controller />
                        <Routes>
                            {/* <Route exact path="/" element={<Login />} /> */}
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/Contact" element={<Contact />} />

                        </Routes>
                    </main>
                </div>
            );
        } else {
            body = (
                <div>
                    <main style={{ flexGrow: 1 }}>
                        <Controller />
                        <Routes>
                            {/* <Route exact path="/" element={<Login />} /> */}
                            <Route exact path="/" element={<Home />} />
                            {/* <Route exact path="/Feedbackform/:id" element={<Feedbackform />} /> */}

                        </Routes>
                    </main>
                </div>
            );

        }

        return <Router>{body}</Router>;
    }
}

export default Routers;
