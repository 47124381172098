import React, { Component } from 'react';
import { Container, Typography, Button } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class HeroSection extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000, // Adjust slider speed
            pauseOnHover: false, // Slide will not stop when hovered over
        };

        return (
            <div style={{ height: '500px', position: 'relative', overflow: 'hidden' }}>
                <Slider {...settings} style={{ width: '100%', height: '100%' }}>
                    <div>
                        <img
                            src='https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/carousels/home-page/desktop/pre-launch-swift/1600x570_01-webp.ashx?modified=20240520093621'
                            alt="Slide 1"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </div>
                    <div>
                        <img
                            src='https://hyperlocalcd4.azureedge.net/-/media/images/feature/arena/channel-specific-global-content/car-brands/s-presso/mast-heads/default/cng-desktop-webp.ashx?modified=20240807051837'
                            alt="Slide 2"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </div>

                    {/* Add more slides if needed */}
                </Slider>
                {/* <Container style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    textAlign: 'center'
                }}>
                    <Typography variant="h2" style={{ marginBottom: '16px', color: 'white' }}>
                        Welcome to Maruti Suzuki
                    </Typography>
                    <Typography variant="h5" style={{ marginBottom: '32px', color: 'white' }}>
                        Your trusted car dealership
                    </Typography>
                    <Button variant="contained" color="primary">
                        Explore Cars
                    </Button>
                </Container> */}
            </div>
        );
    }
}

export default HeroSection;
