import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';

class ContentSection extends Component {
    render() {
        return (
            <Box className="content-section" sx={{ padding: '20px', backgroundColor: '#f9f9f9', color: '#333', fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '25px' }}>
                    Welcome to{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Tushi Motors
                    </Box>{' '}
                    Maruti Suzuki Showroom ARENA in{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Ring Road Mangalabag Cuttack, Cuttack
                    </Box>
                </Typography>

                <Typography variant="body1" paragraph sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                    Walk-in at the renowned Tushi Motors Maruti Suzuki ARENA car showroom in Ring Road Mangalabag Cuttack, Cuttack, where we provide the latest deals, seamless end-to-end sales & services for all ARENA models, such as the Alto K10, Brezza, Celerio, WagonR, Swift, Dzire, S-Presso, Eeco, and Ertiga. Experience our exceptional range of Maruti Suzuki ARENA cars today! If you wish to buy a car in Ring Road Mangalabag Cuttack or experience an easy car exchange, all you need to search for is - “car showroom near me” which will bring you to Tushi Motors in Ring Road Mangalabag Cuttack, Cuttack.
                </Typography>

                <Typography variant="body1" paragraph sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                    Don't wait to experience Maruti Suzuki ARENA car for yourself – book a test drive today! Our user-friendly website makes it easy to register online. Plus, our Customer Relationship Managers are here to help you schedule your test drive at a time that works for you. We're dedicated to providing an outstanding experience every step of the way - before, during, and after you're behind the wheel. This includes - complete assistance in the car buying process, car-related inquiries, paperwork, and car servicing with a pick-and-drop facility.
                </Typography>

                <Typography variant="body1" paragraph sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                    Get the best car buying experience at{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Tushi Motors
                    </Box>{' '}
                    Maruti Suzuki ARENA! Our Customer-First motto ensures you get an easy, seamless car-buying process. Our virtual showroom gives you the power to take a closer look at different car models and variants, compare prices, calculate EMI, get a quote to know the on-road price, and even e-book your car online. Plus, you get a 360-degree inside view of your favourite Maruti Suzuki ARENA car.
                </Typography>

                <Typography variant="body1" paragraph sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                    Worried about a car loan? We've got you covered! Our car financing partnerships with leading companies get you the best rates, easy down payments, and EMI plans. For extra peace of mind, we offer exclusive motor insurance through Maruti Insurance Broking, an IRDA-certified company, so you can be sure your new ride is safe.
                </Typography>

                <Typography variant="body1" paragraph sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 400, fontSize: '14px' }}>
                    At{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Tushi Motors
                    </Box>{' '}
                    Maruti Suzuki ARENA car showroom, an authorized service center in{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Ring Road Mangalabag Cuttack, Cuttack
                    </Box>, we want to make sure your car receives the best care possible. So, book a service online - it's fast and easy! And if you need any help, just give us a call at{' '}
                    <Box component="span" sx={{ color: 'black', fontWeight: 'bold' }}>
                        04071326590
                    </Box>{' '}
                    and we'll be more than happy to help. Get the best car buying and car service experience with us!
                </Typography>
            </Box>
        );
    }
}

export default ContentSection;
