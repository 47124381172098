import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import Header from '../../Home/components/headers';
import Footer1 from '../../Home/components/footer1';
import Footer from '../../Home/components/footer';
const styles = {
    container: {
        paddingTop: 20,
        paddingBottom: 20
    },
    paper: {
        padding: 20,
        textAlign: 'center',
        backgroundColor: '#f5f5f5'
    },
    title: {
        marginBottom: 20,
        fontWeight: 'bold'
    },
    textField: {
        marginBottom: 20
    },
    button: {
        marginTop: 10
    }
};

class ContactUs extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <Header />
                <Container className={classes.container}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} sm={8} md={6}>
                            <Paper className={classes.paper}>
                                <Typography variant="h4" className={classes.title}>
                                    Contact Us
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    River Oaks Plaza, Mahanadi Ring Road,
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Plot no.-1507, Buxibazar, Cuttack, Odisha -753001
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Phone: <strong>07941050788</strong>
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    We would love to hear from you! Please fill out the form below, and we will get back to you as soon as possible.
                                </Typography>
                                <Box component="form" noValidate autoComplete="off">
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Mobile No"
                                        variant="outlined"
                                        className={classes.textField}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        className={classes.textField}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Send Message
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Footer1 />
                <Footer />
            </>
        );
    }
}

export default withStyles(styles)(ContactUs);
